
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        





























































































.tv-choice {
  position: relative;
  z-index: 1;
  overflow: hidden;
  width: 100%;
  background: $c-cultured;
}

.tv-choice__title {
  @extend %text-center;

  margin: 0 auto $spacing * 4;

  @include mq(l) {
    margin-bottom: $spacing * 4.4;
  }
}

.tv-choice__item {
  flex-shrink: 0;
  width: 75vw;
  padding: $spacing * 1.25 $spacing;
  background: $white;
  border-radius: 1.5rem;
  box-shadow: $box-shadow-lvl-2;

  @include mq(m) {
    position: static;
    width: 100%;
    border-radius: 2rem;
  }

  @include mq(l) {
    padding: $spacing * 2.5 $spacing * 2;
  }

  @include mq(xl) {
    padding: $spacing * 2.5 $spacing * 5;
  }
}

.tv-choice__item__title {
  @extend %text-center;

  margin-top: 0;

  ::v-deep strong {
    display: block;
    font-size: 1.5em;
  }
}

.tv-choice__item__picture {
  position: relative;
  width: 100%;
  height: 4rem;
  margin: $spacing auto;

  img {
    @include image-fit(contain);
  }
}

.tv-choice__item__subtitle {
  @extend %text-center;
  @extend %fw-semi;

  margin: $spacing 0;
  font-family: $ff-alt;

  @include mq(l) {
    margin: $spacing * 2 0;
  }
}

.tv-choice__item__listing {
  margin: $spacing 0;

  ::v-deep {
    .listing__list__item {
      margin-bottom: $spacing * 0.5;
    }

    .listing__list__item__icon {
      margin-right: $spacing * 0.5;

      &.shared-icons-crossrounded {
        fill: $c-dev-error;
      }
    }
  }
}

.tv-choice__item__link {
  display: block;
  width: fit-content;
  margin: $spacing auto 0;

  @include mq(l) {
    margin: $spacing * 2 auto 0;
  }
}

.tv-choice__link {
  display: flex;
  width: fit-content;
  margin: $spacing * 1.5 auto 0;

  ::v-deep .btn__icon {
    margin-left: $spacing * 0.5;

    svg {
      width: 1.6rem;
      height: 1.6rem;
    }
  }

  @include mq(l) {
    margin-top: $spacing * 5;
  }
}

.speedlines {
  position: absolute;
  width: 50%;
  height: 200px;

  @include mq(l) {
    width: 50%;
    height: 50%;
  }
}

.speedlines--left {
  top: inherit;
  bottom: 0;
  left: 0;
}

.speedlines--right {
  top: 10%;
  right: -30%;
}
